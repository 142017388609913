import React from 'react'
import styled from 'styled-components'
import { Container, Row, Col } from 'react-awesome-styled-grid'
import { FaGithub, FaLinkedin, FaEnvelope, FaTwitter } from 'react-icons/fa'
import siteConfig from '../../data/siteConfig'
import loadable from '@loadable/component'

import Hero from '../components/hero'
import SEO from '../components/SEO'
import Wrapper from '../components/wrapper'

const Layout = loadable(() => import('../components/layout'))

const Separator = styled.hr`
  margin-top: 24px;
  margin-bottom: 16px;
  background-color: ${({ theme }) => theme.colors.fontColor};
  opacity: 0.2;
`

const Link = styled.a`
  color: ${({ theme }) => theme.colors.fontColor};
`

const Home = ({ className, location }) => {
  const title = siteConfig.siteTitle
  const { keywords } = siteConfig
  return (
    <Layout location={location}>
      <SEO title={title} keywords={keywords} />

      <Hero heroImg={siteConfig.siteCover} title={title} />

      <Wrapper className={className}>
        <Container className="page-content" fluid>
          <Row>
            <Col xs={4} className="avatar">
              <div className="social">
                {siteConfig.social.github && (
                  <a
                    className="social-link github"
                    href={siteConfig.social.github}
                  >
                    <FaGithub className="social-icon" size="32" />
                  </a>
                )}
                {siteConfig.social.linkedin && (
                  <a
                    className="social-link linkedin"
                    href={siteConfig.social.linkedin}
                  >
                    <FaLinkedin className="social-icon" size="32" />
                  </a>
                )}
                {siteConfig.social.twitter && (
                  <a
                    className="social-link twitter"
                    href={siteConfig.social.twitter}
                  >
                    <FaTwitter className="social-icon" size="32" />
                  </a>
                )}
                {siteConfig.social.email && (
                  <a
                    className="social-link email"
                    href={`mailto:${siteConfig.social.email}`}
                  >
                    <FaEnvelope className="social-icon" size="32" />
                  </a>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col>
              <div>
                <p>
                  I like to explore places and I move a lot. I'm an avid DIYer,
                  a motorcyclist, and a coffee fiend.
                </p>
                <p>
                  I'm from Canada, but these days I live mostly in Scotland{' '}
                  <span aria-label="Scottish flag" role="img">
                    🏴󠁧󠁢󠁳󠁣󠁴󠁿
                  </span>{' '}
                  and Portugal{' '}
                  <span aria-label="Portuguese flag" role="img">
                    🇵🇹
                  </span>
                  .
                </p>
                <p>
                  I'm a software developer and I make apps with my pal{' '}
                  <Link href="https://triggersandsparks.com/">
                     Sarah
                  </Link>{' '}
                  at our company{' '}
                  <Link href="https://octopusthink.com">Octopus Think</Link>{' '}
                  <span aria-label="octopus" role="img">
                    🐙
                  </span>
                  .
                </p>
                <p>
                  You can{' '}
                  <Link href="https://octopusthink.com/blog/">
                    read my blog posts
                  </Link>{' '}
                  or{' '}
                  <Link href="https://octopusthink.com/apps/">
                    check out the apps I've written
                  </Link>
                  .
                </p>
              </div>
            </Col>
          </Row>
          <Separator />
        </Container>
      </Wrapper>
    </Layout>
  )
}

export default styled(Home)`
  .page-content {
    max-width: 100%;
    margin-bottom: 40px;
  }

  .avatar {
    align-items: center;
    margin-bottom: 24px;
    flex-direction: column;
  }

  .avatar__image {
    box-shadow: 3px 3px 15px 0px rgba(0, 0, 0, 0.75);
    max-width: 200px;
    border-radius: 100px;
    margin: 0 auto 24px;
  }

  .social {
    margin-top: 12px;
    margin-bottom: 12px;
  }

  .social-link {
    padding: 8px;
    color: #555;
  }

  a.social-link.twitter:hover {
    color: #1da1f2;
  }

  a.social-link.github:hover {
    color: #24292e;
  }

  a.social-link.linkedin:hover {
    color: #0077b5;
  }

  a.social-link.email:hover {
    color: #c23a2b;
  }
`
